
export default {
    getRandomNum: (min, max) => {
        let range = max - min;
        let rand = Math.random();
        return (min + Math.round(rand * range));
    },

    getNowFormatDate: (formatter) => {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let strDate = date.getDate();
        if (month >= 1 && month <= 9) {
            month = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
            strDate = "0" + strDate;
        }
        if (formatter) {
            if (formatter === 'en') {
                return year + '-' + month + '-' + strDate;
            } else {
                return year + '年' + month + '月' + strDate + '日';
            }
        } else {
            return year + '' + month + '' + strDate;

        }
    },
    getNowFormatTime: (formatter) => {
        let date = new Date();
        let hour = date.getHours();
        let minute = date.getMinutes();
        let second = date.getSeconds();
        if (hour >= 0 && hour <= 9) {
            hour = "0" + hour;
        }
        if (minute >= 0 && minute <= 9) {
            minute = "0" + minute;
        }
        if (second >= 0 && second <= 9) {
            second = "0" + second;
        }
        if (formatter) {
            return hour + ':' + minute + ':' + second;
        } else {
            return hour + '' + minute + '' + second;
        }
    },
    sha1: (s) => {
        var data = new Uint8Array(encodeUTF8(s))
        var i, j, t;
        var l = ((data.length + 8) >>> 6 << 4) + 16, s = new Uint8Array(l << 2);
        s.set(new Uint8Array(data.buffer)), s = new Uint32Array(s.buffer);
        for (t = new DataView(s.buffer), i = 0; i < l; i++)
            s[i] = t.getUint32(i << 2);
        s[data.length >> 2] |= 0x80 << (24 - (data.length & 3) * 8);
        s[l - 1] = data.length << 3;
        var w = [], f = [function () {
            return m[1] & m[2] | ~m[1] & m[3];
        }, function () {
            return m[1] ^ m[2] ^ m[3];
        }, function () {
            return m[1] & m[2] | m[1] & m[3] | m[2] & m[3];
        }, function () {
            return m[1] ^ m[2] ^ m[3];
        }], rol = function (n, c) {
            return n << c | n >>> (32 - c);
        }, k = [1518500249, 1859775393, -1894007588, -899497514], m = [
            1732584193, -271733879, null, null, -1009589776];
        m[2] = ~m[0], m[3] = ~m[1];
        for (i = 0; i < s.length; i += 16) {
            var o = m.slice(0);
            for (j = 0; j < 80; j++)
                w[j] = j < 16 ? s[i + j] : rol(w[j - 3] ^ w[j - 8] ^ w[j - 14]
                    ^ w[j - 16], 1), t = rol(m[0], 5) + f[j / 20 | 0]() + m[4]
                    + w[j] + k[j / 20 | 0] | 0, m[1] = rol(m[1], 30), m.pop(),
                    m.unshift(t);
            for (j = 0; j < 5; j++)
                m[j] = m[j] + o[j] | 0;
        }
        t = new DataView(new Uint32Array(m).buffer);
        for (var i = 0; i < 5; i++)
            m[i] = t.getUint32(i << 2);

        var hex = Array.prototype.map.call(
            new Uint8Array(new Uint32Array(m).buffer), function (e) {
                return (e < 16 ? "0" : "") + e.toString(16);
            }).join("");

        return hex;
    },
    acc2ASCIICode(acc) {
        let code_acc = "";
        for (let i = 0; i < acc.length; i++) {
            code_acc = code_acc.concat(acc[i].charCodeAt() + '').concat('|');
        }
        return code_acc;
    },
    aSCIICode2Acc(ascii) {
        const arr = ascii.split("|");
        let acc = "";
        for (let i = 0; i < arr.length; i++) {
            if (arr[i]) {
                acc = acc.concat(String.fromCharCode(arr[i]));
            }
        }
        return acc;
    },
    md5(s){
        const CryptoJS = require('crypto-js')
        return CryptoJS.MD5(s).toString();
    }
}

function encodeUTF8(text) {
    const code = encodeURIComponent(text);
    const bytes = [];
    for (var i = 0; i < code.length; i++) {
        const c = code.charAt(i);
        if (c === '%') {
            const hex = code.charAt(i + 1) + code.charAt(i + 2);
            const hexVal = parseInt(hex, 16);
            bytes.push(hexVal);
            i += 2;
        } else bytes.push(c.charCodeAt(0));
    }
    return bytes;
}