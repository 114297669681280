<template>
    <el-dialog title="批量操作结果" :visible.sync="show" width="50%" custom-class="message-dialog" append-to-body>
        <div style="display: flex; align-items: center; margin-left: -10px;">
            <div style=" font-size:20px ">批量<span style="color:red">【{{typeTitle}}】</span>操作结果</div>
        </div>
        <ul class="message-dialog-top">
            <li>
                <span class="label">成功</span>
                <span class="value success">{{ successCount }}</span>
            </li>
            <li>
                <span class="label">失败</span>
                <span class="value error">{{ failureCount }}</span>
            </li>
            <li>
                <span class="label">总共</span>
                <span class="value info">{{ totalCount }}</span>
            </li>
        </ul>
        <el-table ref="tableIns" :data="detailList" border class="table-box" max-height="400">
            <el-table-column align="center" :show-overflow-tooltip="true" prop="originTag" label="单号" width="170">
            </el-table-column>
            <el-table-column align="center" :show-overflow-tooltip="true" prop="state" label="结果" width="60">
                <template slot-scope="{ row }">
                    <span :class="[row.state === 'Y' ? 'success' : 'error']">
                        {{ row.state === 'Y' ? '成功' : '失败' }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column align="center" :show-overflow-tooltip="true" prop="reason" label="原因"> </el-table-column>
            <el-table-column align="center" :show-overflow-tooltip="true" prop="description" label="备注">
            </el-table-column>
        </el-table>
    </el-dialog>
</template>

<script>
export default {
    name: 'messageDialog',
    data() {
        return {
            show: true,
            successCount: '',
            failureCount: '',
            totalCount: '',
            detailList: [],
            typeTitle:''
        }
    },
    created() {
        // setTimeout(() => {
        //     this.show = false
        // }, 5000)
    },
    methods: {}
}
</script>

<style lang="scss" scoped>
.message-dialog {
    ul,
    li {
        padding: 0;
        margin: 0;
        list-style: none;
    }
    .el-dialog__header {
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .el-dialog__headerbtn {
            position: inherit;
        }
        border-bottom: 1px solid #eee;
        .el-dialog__title {
            font-weight: 700;
        }
    }
    .el-dialog__body {
        padding: 10px 20px 20px;
        border-bottom: 1px solid #eee;
    }
    .message-dialog-top {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding-bottom: 10px;
        font-size: 18px;
        font-weight: 700;
        .label {
            margin-right: 10px;
        }
    }
    .el-table--medium .el-table__cell {
        padding: 0 !important;
    }
    .el-table__header {
        .el-table__cell {
            padding: 5px 0 !important;
        }
    }
    .el-table-column--selection .cell {
        padding-right: 10px;
    }
    .el-table__header-wrapper {
        th.el-table__cell {
            background-color: #f9fafc;
        }
    }
    .success {
        color: #6fcd74;
    }
    .error {
        color: #e55764;
    }
    .info {
        color: #333333;
    }
}
</style>
