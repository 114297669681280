export default {
    session: {
        set(key, value) {
            sessionStorage.setItem(key, value);
        },
        get(key) {
            if(sessionStorage.getItem(key)) {
                return sessionStorage.getItem(key);
            }
            return "";
        },
        remove(key){
            sessionStorage.removeItem(key);
        }
    },
    local: {
        set(key, value) {
            localStorage.setItem(key, value);
        },
        get(key) {
            if(localStorage.getItem(key)) {
                return localStorage.getItem(key);
            }
            return "";
        },
        remove(key){
            localStorage.removeItem(key);
        }
    }
}