<template>
    <el-button v-if="isShow" v-bind="$attrs" v-on="$listeners" class="basic-button">
        <svg-icon v-if="iconClass && !$attrs.loading" :icon-class="iconClass" class-name="svg-icon"></svg-icon>
        <slot> </slot>
    </el-button>
</template>
<script>
// import lodash from 'lodash'
import svgIcon from '@/components/SvgIcon/index'
// import { mapGetters } from 'vuex';
export default {
    name: 'basicButton',
    inheritAttrs: false,
    props: {
        //  ['code', 'delay','isShow'],
        delay: {
            type: Number | String,
            default: 500
        },
        isShow: {
            type: Boolean,
            default: true
        },
        iconClass: {
            type: String
        }
    },
    components: {
        svgIcon
    },
    data() {
        return {
            attrsExt: {}
        }
    },
    // computed: {
    // ...mapGetters(['btnPermissions']),
    // isShow() {
    //     if (!this.code) return true;
    //     return lodash.includes(this.btnPermissions, this.code);
    // }
    // },
    mounted() {
        // const clickEvent = lodash.get(this.$listeners, 'click')
        // if (clickEvent) {
        //     const options = { leading: true, trailing: false }
        //     this.attrsExt.click = lodash.debounce(clickEvent, +this.delay, options)
        // }
    }
}
</script>
<style lang="scss" scoped>
.basic-button {
    font-size: 12px;
    padding: 0 20px;
    line-height: 36px;
    color: #000 !important;
    font-weight: 500;
    border-radius: 0;
    border: none;
    background: #c6e7ff;

    .svg-icon {
        font-size: 18px;
        margin-right: 5px;
        vertical-align: -0.2em;
    }
    &:hover {
        background: #9ed2f8;
    }

    &.is-disabled {
        background: #c6e7ff;
        filter: grayscale(75%);
    }

    &.is-disabled:hover {
        filter: grayscale(75%);
    }
    & .el-icon-loading {
        font-size: 16px;
    }
}
</style>
