export default {
    inserted: function (el, binding) {
        const [fn, event, time] = binding.value
        let timer
        el.addEventListener(event, () => {
            if (timer) return
            timer = setTimeout(() => {
                fn.call(this)
                timer = null
            }, time)
        })
    }
}