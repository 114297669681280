/**
 * 主进程与渲染进程通信频道定义
 * Definition of communication channels between main process and rendering process
 */

const ipcApiRoute = {
  actionMainWindow: 'controller.os.actionMainWindow',
  createWindow: 'controller.os.createWindow',
  closeWindow: 'controller.os.closeWindow',
  miniWindow: 'controller.os.miniWindow',
  closeWindowAll: 'controller.os.closeWindowAll',
  getWCid: 'controller.os.getWCid',
  setDomainName: 'controller.os.setDomainName',

  ipcSendMsg: 'controller.framework.ipcSendMsg',

  reprint: 'controller.restful.doHttpRequest',

  print: 'controller.print.print',

  getPrinters: 'controller.example.getPrinters',
  restartApp: 'controller.example.restartApp',
  getVersion: 'controller.example.getVersion',
  checkMicrophone: 'controller.example.checkMicrophone',
  openFolder: 'controller.example.openFolder',
  startThirdApp: 'controller.example.openSoftware', // ipc.invoke(ipcApiRoute.startThirdApp, 'ToDesk_Lite.exe').then(r => {})

  notifyMainPrint: 'notify.main.print',
  notifyPageLoad: 'notify.page.load',
  notifyPageRefresh: 'notify.page.refresh',

  trtcGetMicDevice: 'controller.trtc.getMicDevice',
  trtcEnterRoom: 'controller.trtc.enterRoom',
  trtcExitRoom: 'controller.trtc.exitRoom',
  trtcSetMicVolume: 'controller.trtc.setMicVolume',
  trtcSetSoundVolume: 'controller.trtc.setSoundVolume',

  fileCacheSet: 'controller.fileCache.setCache',
  fileCacheGet: 'controller.fileCache.getCache',
  fileCacheDel: 'controller.fileCache.delCache',

  exportLog: 'controller.log.exportLog',

  startDown: 'controller.download.startDown',
}

const specialIpcRoute = {
  appUpdater: 'app.updater',
  window1ToWindow2: 'window1-to-window2',
  window2ToWindow1: 'window2-to-window1',
}

export {
  ipcApiRoute, specialIpcRoute
}

