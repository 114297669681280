import Decimal from 'decimal.js';

export default {
  /**
   * amount1 + amount2
   * @param amount1
   * @param amount2
   * @param fixed 保留位数，默认2
   * @returns {string}
   */
  add(amount1, amount2, fixed = 2) {
    return new Decimal(amount1).plus(new Decimal(amount2)).toFixed(fixed);
  },
  /**
   * amount1 - amount2
   * @param amount1
   * @param amount2
   * @param fixed 保留位数，默认2
   * @returns {string}
   */
  subtract(amount1,amount2, fixed = 2){
    return new Decimal(amount1).sub(new Decimal(amount2)).toFixed(fixed);
  },
  /**
   * amount1 * amount2
   * @param amount1
   * @param amount2
   * @param fixed 保留位数，默认2
   * @returns {string}
   */
  mul(amount1, amount2, fixed = 2) {
    return new Decimal(amount1).mul(new Decimal(amount2)).toFixed(fixed);
  },
  /**
   * amount1 / amount2
   * @param amount1
   * @param amount2
   * @param fixed 保留位数，默认2
   * @returns {string}
   */
  divide(amount1,amount2, fixed = 2){
    return new Decimal(amount1).div(new Decimal(amount2)).toFixed(fixed);
  },
  /**
   * amount1 == amount2 ?
   * @param amount1
   * @param amount2
   * @returns {boolean}
   */
  equals(amount1, amount2){
    return new Decimal(amount1).equals(new Decimal(amount2));
  },
  /**
   * amount1 > amount2 ?
   * @param amount1
   * @param amount2
   * @returns {boolean}
   */
  greaterThan(amount1, amount2){
    return new Decimal(amount1).greaterThan(new Decimal(amount2));
  },
  /**
   * amount1 >= amount2 ?
   * @param amount1
   * @param amount2
   * @returns {boolean}
   */
  greaterThanOrEquals(amount1, amount2){
    return new Decimal(amount1).greaterThanOrEqualTo(new Decimal(amount2));
  },
  /**
   * amount1 < amount2 ?
   * @param amount1
   * @param amount2
   * @returns {boolean}
   */
  lessThan(amount1, amount2){
    return new Decimal(amount1).lessThan(new Decimal(amount2));
  },
  /**
   * amount1 <= amount2 ?
   * @param amount1
   * @param amount2
   * @returns {boolean}
   */
  lessThanOrEquals(amount1, amount2){
    return new Decimal(amount1).lessThanOrEqualTo(new Decimal(amount2));
  }
}

