import throttle from './module/throttle.js'
import debounce from './module/debounce.js'
import throttletwo from './module/throttletwo.js'
import focusInput from './module/focusInput.js'

const importDirective = Vue => {
    // 自定义节流指令
    Vue.directive('throttle', throttle)
    
    // 自定义组件防抖
    Vue.directive('debounce', debounce)

    // el-button防抖
    Vue.directive('noMoreClick', throttletwo)

    // input聚焦自动加上闪烁边框提示
    Vue.directive('focusInput', focusInput)
}

export default importDirective