/* eslint-disable */
const log = console.log;
export default {
	normal: (str) => {
		log("%c%s",
            "color: #666;",
            str);
	},
	error: (str) => {
		log("%c%s",
            "color: #bf3232;",
            str);
	},
	success: (str) => {
		log("%c%s",
            "color: #247d0b;",
            str);
	},
	json: (obj) => {
		log(obj);
	}
}