export default {
    /**
     * 节点是否存在了 className
     * @param {*} el 节点
     * @param {*} className className
     */
    hasClass (el, className) {
      if (el && el.className) {
        return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'))
      }
      return false
    },
    /**
     * 添加 className
     * @param {*} el 节点
     * @param {*} className className
     */
    addClass (el, className) {
      if (el && !this.hasClass(el, className)) el.className += ' ' + className
    },
    /**
     * 移除 className
     * @param {*} el 节点
     * @param {*} className className
     */
    removeClass (el, className) {
      if (this.hasClass(el, className)) {
        var reg = new RegExp('(\\s|^)' + className + '(\\s|$)')
        el.className = el.className.replace(reg, ' ')
      }
    },
    /**
     * 移除全部 className
     * @param {*} el 节点
     */
    removeAllClass (el) {
      el.className = ''
    }
  }