export default {
  /*
   * 数组的递归条件查找，返回找到的结果集
   *
   * recursiveFind(list, item => {
      return item["xxx"] === xxx
      }, result)
    * @returns result
  */
  recursiveFind(arr, fn, result) {
    const that = this;
    arr.forEach(item => {
      if (item.children) {
        that.recursiveFind(item.children, fn, result)
      } else {
        if (fn(item)) {
          result.push(item);
        }
      }
    })
  },
  // isElectron() {
  //   // Renderer process
  //   if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
  //     return true
  //   }
  //   // Main process
  //   if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
  //     return true
  //   }
  //   // Detect the user agent when the `nodeIntegration` option is set to false
  //   return typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0
  // },
  // isOsx: process.platform === 'darwin',
  // isWin: process.platform === 'win32',
  // isLinux: process.platform === 'linux',
  getHashSearchParam(key) {
    const url = location.href
    // 获取 hash 值，不包含 '#' 号
    const hash = url.substring(url.indexOf("#") + 1)
    // 查找 '?' 号所在的索引
    const searchIndex = hash.indexOf("?")
    // '?' 号后面接的是索引参数，如果找到则+1，去除'?' 号
    const search = searchIndex !== -1 ? hash.substring(searchIndex + 1) : ""
    // 把搜索参数字符串提过URLSearchParams转成对象形式
    const usp = new URLSearchParams(search)
    // 通过URLSearchParams自带的get方法，查询键所对应的值
    return usp.get(key)
  },

  getUrlParams (params) {
    if (!params) return;
    const url = window.location.href;
    const paramsArr = url.split('?')[1] ? url.split('?')[1].split('&') : [];
    const obj = {};
    paramsArr.forEach(item => {
      const arr = item.split('=');
      obj[arr[0]] = arr[1];
    });
    return obj[params] ? decodeURIComponent(obj[params]) : '';
  },
  
}
