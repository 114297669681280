const CryptoJS = require('crypto-js');

/**
 * 生成密钥字节数组, 原始密钥字符串不足128位, 补填0.
 * @param {string} key - 原始 key 值
 * @return Buffer
 */
function fillKey(key,keySize = 128){
  const filledKey = Buffer.alloc(keySize / 8);
  const keys = Buffer.from(key);
  if (keys.length < filledKey.length) {
    filledKey.map((b, i) => filledKey[i] = keys[i]);
  }

  return filledKey;
}



// AES CBC模式加密密
function CBC_encrypt(message, key, iv) {
    // utf8字符串—>WordArray对象，WordArray是一个保存32位整数的数组，相当于转成了二进制
    let keyHex = CryptoJS.enc.Utf8.parse(key); //
    let ivHex = CryptoJS.enc.Utf8.parse(iv);
    let messageHex = CryptoJS.enc.Utf8.parse(message);
    let encrypted = CryptoJS.AES.encrypt(messageHex, keyHex, {
        iv: ivHex,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();// base64结果
    //return encrypted.ciphertext.toString();   // 二进制结果
}
// AES CBC模式解密
function CBC_decrypt(messageBase64, key, iv) {
    // 如果加密后没有转成base64,那么先要转成base64再传入
    //let encryptedHexStr = CryptoJS.enc.Hex.parse(word);   // 从二进制文本转成二进制
    //messageBase64 = CryptoJS.enc.Base64.stringify(encryptedHexStr);    // 转成base64
    let keyHex = CryptoJS.enc.Utf8.parse(key);
    let ivHex = CryptoJS.enc.Utf8.parse(iv);
    let decrypt = CryptoJS.AES.decrypt(messageBase64, keyHex, {
        iv: ivHex,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
}
// AES ECB模式加密，没有iv
function ECB_encrypt(message, key) {
    // utf8字符串—>WordArray对象，WordArray是一个保存32位整数的数组，相当于转成了二进制
    let keyHex = CryptoJS.enc.Utf8.parse(key); //
    let messageHex = CryptoJS.enc.Utf8.parse(message);
    let encrypted = CryptoJS.AES.encrypt(messageHex, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();// base64结果
    //return encrypted.ciphertext.toString();   // 二进制结果
}
// AES ECB模式解密
function ECB_decrypt(messageBase64, key) {
    // 如果加密后没有转成base64,那么先要转成base64再传入
    //let encryptedHexStr = CryptoJS.enc.Hex.parse(word);   // 从二进制文本转成二进制
    //messageBase64 = CryptoJS.enc.Base64.stringify(encryptedHexStr);    // 转成base64
    let keyHex = CryptoJS.enc.Utf8.parse(key);
    let decrypt = CryptoJS.AES.decrypt(messageBase64, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
}

export default {
	fillKey,
	CBC_encrypt,
	CBC_decrypt,
	ECB_encrypt,
	ECB_decrypt,
}