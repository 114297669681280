<template>
	<div id="app">
		<keep-alive>
			<router-view v-if="$route.meta.keepAlive"></router-view>
		</keep-alive>
		<router-view v-if="!$route.meta.keepAlive"></router-view>
		<div v-if="env && env !== 'production'" class="pre-tip focus-input-item">公测</div>
	</div>
</template>

<script>
import watermark from "@/utils/waterMark";
import {ipc} from "@/utils/ipcRenderer";
import {ipcApiRoute} from "@/api/main";

export default {
	name: 'APP',
	data() {
		return {
			env: 'production'
		}
	},
	created() {
		const that = this;
		ipc.invoke(ipcApiRoute.fileCacheGet, {
			cacheName: 'INJECT_ENV'
		}).then(r => {
			if(r) {
				that.env = r;
			}
		})
	},
	watch: {
		$route: {
			handler: function (route) {
				if (route.name && route.name !== 'Login') {
					this.setWatermark()
				} else {
					watermark.set('')
				}
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		setWatermark() {
			let name = this.$cache.local.get('name');
			let phone = this.$cache.local.get('phone');
			if (name && phone) {
				watermark.set(name + " " + phone.substr(phone.length - 4, 4));
			}
		}
	},
}
</script>

<style lang="scss">
@import 'assets/styles/font.css';

.pre-tip {
	width: 150px;
	height: 150px;
	border-radius: 75px;
	position: fixed;
	left: 20px;
	bottom: 50px;
	text-align: center;
	line-height: 150px;
	font-size: 20px !important;
	font-weight: bold !important;
}
</style>
