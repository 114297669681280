import elClassTool from "../../utils/elClassTool";

/**
 * 默认：v-focusInput：加上这个指令代表当前input聚焦时添加样式，失去焦点时移除样式
 * v-focusInput:"1"，代表操作的时当前input的父级
 */
export default {
    inserted: function (el, binding, vnode) {
        let node = el;
        if(binding.value && binding.value == 1) {
            node = el.parentNode;
        }
        el.onfocus = function () {
            if (!elClassTool.hasClass(node, 'focus-input-item')) {
                elClassTool.addClass(node, 'focus-input-item')
            }
        }
        el.onblur = function () {
            if (elClassTool.hasClass(node, 'focus-input-item')) {
                elClassTool.removeClass(node, 'focus-input-item')
            }
        }
    }
}